import { stripRichTextWrapperTag } from '@/utils/html';
import {
  routerLinks,
  offerLinks,
  countryLinks,
  regionLinks,
} from '@/utils/fetch-links';
import {
  getType,
  getCardType,
  cardCountryAdapter,
  cardRegionAdapter,
  cardGuideAdapter,
  cardArticleAdapter,
} from '@/utils/helpers/get-cards';

export const cardsAdapter = (cards, i18n, enhancedLinkSerializer) => {
  return cards.map(({ edito_carousel_card: editoCarouselCard }) => {
    const cardType = getCardType(editoCarouselCard.type);
    const adatpterData = {
      editoCarouselCard,
      cardType,
      i18n,
      enhancedLinkSerializer,
    };

    switch (cardType) {
      case 'guide':
        return cardGuideAdapter(adatpterData);
      case 'region':
        return cardRegionAdapter(adatpterData);
      case 'country':
        return cardCountryAdapter(adatpterData);
      case 'article':
      default:
        return cardArticleAdapter(adatpterData);
    }
  });
};

const getOrdering = (order, queryType) => {
  switch (order) {
    case 'DESC date':
      return `[my.${queryType}.date desc, document.last_publication_date desc]`;
    case 'ASC title':
    default:
      return `[my.${queryType}.title]`;
  }
};
const getItems = async (
  { pageId, lang, page, type, offer, cardsPerPage, orderBy },
  prismic
) => {
  const queryType = getType(type);
  const order = getOrdering(orderBy, queryType);
  const query = [prismic.predicates.at('document.type', queryType)];

  if (offer?.id) {
    query.push(prismic.predicates.at(`my.${queryType}.offer`, offer.id));
  }

  if (queryType === 'page_country') {
    query.push(prismic.predicates.at('my.page_country.region', pageId));
  }

  const items = await prismic.api.query(query, {
    orderings: order,
    pageSize: cardsPerPage,
    page,
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...countryLinks,
      ...regionLinks,
    ],
    lang,
  });

  return {
    totalPages: items.total_pages,
    cards: items.results.map((result) => ({
      edito_carousel_card: { ...result, link_type: 'Document' },
    })),
  };
};

export default async (
  { primary },
  { pageId, page, i18n, prismic, enhancedLinkSerializer }
) => {
  const {
    title,
    offer,
    with_pagination: withPagination,
    cards_per_page: cardsPerPage,
    order_by: orderBy,
    type,
  } = primary || {};

  const lang = i18n.localeProperties.iso;

  const cardItems = await getItems(
    { pageId, lang, page, type, offer, withPagination, cardsPerPage, orderBy },
    prismic
  );
  const { cards, totalPages } = cardItems || {};

  return {
    title: title && stripRichTextWrapperTag(title),
    withPagination,
    totalPages,
    withCarousel: primary.edito_carousel_active,
    cards: cardsAdapter(cards, i18n, enhancedLinkSerializer),
  };
};
